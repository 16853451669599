.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  text-transform: none;
  text-decoration: none;
  color: black;
  z-index: 1;
}

div{
  max-width: 1100px;
}

.spacer {
  width: 100%;
  height: 63px;
  position: relative;
  top: 0px;
}

.nav-container {
  width: 100%;
  padding: 20px;
  font-family: helvetica;
  display: flex;
  background-color: #8ba9a4;
  color: white;
  position: fixed;
  top: 0px;
  z-index: 9;
}

.title {
  float: left;
  margin-left: 0px;
  letter-spacing: 6px;
  font-weight: bold;
  font-size: 20px;
  color: white;
}

.testimonial-container {
  width: 90%;
  margin-left: 20px;
  display: flex;
  flex-flow: wrap;
  margin-bottom:100px;
}

.testimonial {
  background-color: #fbf1da5c;
  padding: 20px;
  border-radius: 30px;
  font-size: 13px;
  line-height: 17px;
  color: #000000b0;
  margin: 20px;
  max-width:300px;
}


span.name {
  font-weight: bold;
  margin-top: 20px;
  position: relative;
  display: block;
}
.link-container {
  float: left;
  position: absolute;
  right: 100px;
  display: none;
}

.hamburger-menu {
  background-image: url(./menu.png);
  display: block;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  background-size: contain;
  float: right;
  display: block;
  position: fixed;
  right: 20px;
  z-index: 9999;
  top: 20px;
}

.menuOverlay {
  position: absolute;
  top: 63px;
  background-color: #8ba9a4;
  height: calc(100vh - 63px);
  width: 100vw;
  left: 0px;
  transform: translateY(-100vh);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: center;
  font-size: 23px;
  letter-spacing: 3px;
}

.mobile-link {
  padding: 22px;
  color: white;
  font-size: 17px;
}

.link {
  float: left;
  margin-left: 50px;
  cursor: pointer;
}

.link:hover {
  opacity: 0.5;
}

.hero-image {
  width: 100%;
  background-color: blue;
  height: 270px;
}

.homepage-content {
  width: 80%;
  font-size: 19px;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
  color: #00000094;
  margin-left: 20px;
  /* top: -30px; */
  position: relative;
}

.headline {
  font-size: 10vw;
  margin-left: 20px;
  margin-top: 20px;
  max-width: 300px;
  z-index: 1;
  position: relative;
}

.sub-copy {
  font-weight: initial;
  font-size: 13px;
  margin-top: 10px;
  padding-bottom: 100px;
}

.collage {
  background-image: url(./collage.jpg);
}

footer {
  width: 100%;
  padding: 15px;
  position: fixed;
  background-color: #8ba9a4;
  bottom: 0px;
}

.footer-contact {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 45px;
  padding: 13px;
  background-color: #8ba9a4;
  color: white;
  font-weight: bold;
  letter-spacing: 4px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  cursor: pointer;
}

.social-container {
  display: flex;
  flex-direction: row;
  width: 100px;
  justify-content: space-around;
}

.facebook {
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(./facebook-icon.png);
  opacity: 1;
  cursor: pointer;
}

.facebook:hover {
  opacity: 0.8;
}

.instagram {
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(./insta-icon.png);
  opacity: 1;
  cursor: pointer;
}

.instagram:hover {
  opacity: 0.8;
}

.gallery {
  width: 90%;
  position: relative;
  margin-left: 5%;
  padding-bottom: 200px;
}

.gallery p {
  padding-bottom: 50px;
}

.contact-form-container {
  width: 90%;
  position: relative;
  margin-left: 5%;
  padding-bottom: 190px;
}

.contact-header {
  font-weight: bold;
  font-size: 16px;
  width: 70%;
  margin-left: 20px;
  color: #00000094;
}

.contact-item-container {
  margin-left: 20px;
  border: 2px solid #8ba9a421;
  padding: 7px;
  margin-bottom: 70px;
  margin-top: 40px;
  width: 360px;
  color: white;
  font-weight: bold;
  background-color: #868686;
  letter-spacing: 1px;
  padding-bottom: 23px;
  padding-left: 20px;
}

.phone-icon {
  width: 20px;
  height: 20px;
  background-image: url(./phone.png);
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
  top: 6px;
  padding-right: 20px;
}

.phone {
  position: relative;
  top: 9px;
}

.email {
  padding-top: 25px;
}

.email-icon {
  width: 20px;
  height: 20px;
  background-image: url(./email.png);
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
  top: 6px;
  padding-right: 20px;
}

.homepage-content .sub-copy {
  display: none;
}

div#ReactGridGallery{
  margin-left:20px;
}
.sampleImageTitleContainer {
  margin-left: 20px;
}

.sampleTitle {
  background-color: #8ba9a4;
  width: 230px;
  float: left;
  padding: 50px;
  margin: 10px;
  text-align: center;
  font-family: helvetica;
  font-weight: bold;
  color: white;
  letter-spacing: 0px;
  cursor: pointer;
  padding-bottom: 20px;
  padding-top: 20px;
  cursor: pointer;
}



.sampleTitle:hover {
  opacity: 0.5;
}


.ReactGridGallery_tile {
  opacity: 0.8;
  margin: 10px !important;
}


.ReactGridGallery_tile:hover {
  opacity: 1;
}

.backtosamples {
  margin-left: 20px;
  margin-bottom: 40px;
  margin-top: -10px;
}

.sampleImageContainer {
  width: 90%;
  height: 100vh;
  position: relative;
  display: block;
  margin-bottom: 100px;
}

@media screen and (min-width: 700px) {
  .headline {
    font-size: 6vw;
    max-width: 500px;
  }

  .homepage-content .sub-copy {
    display: block;
  }
}



@media screen and (min-width: 1000px) {

  .backtosamples
  {
    margin-left:100px;
  }

.sampleTitle {
  background-color: #8ba9a4;
  width: 230px;
  float: left;
  padding: 50px;
  margin: 10px;
  max-width: 160px;
  text-align: center;
  font-family: helvetica;
  font-weight: bold;
  color: white;
  letter-spacing: 0px;
  cursor: pointer;
}

  div#ReactGridGallery{
    margin-left:100px;
  }
  .sampleImageTitleContainer {
    margin-left: 100px;
}
  .footer-contact {
    right: 100px;
    left: initial;
    transform: initial;
  }

  .contact-item-container {
    margin-left: 100px;
  }
  .contact-header {
    margin-left: 100px;
  }

  .contact-form-container {
    margin-left: 100px;
    width: 60%;
  }

  footer {
  }

  .title {
    margin-left: 90px;
    color: black;
  }

  .link-container {
    display: block;
  }

  .hamburger-menu {
    display: none;
  }

  .nav-container {
    background-color: white;
    color: black;
  }

  .headline {
    margin-left: 100px;
    font-size: 6vw;
    max-width: 520px;
  }

  .homepage-content {
    font-size: 21px;
    margin-left: 100px;
    top: -30px;
    position: relative;
  }

  .social-container {
    margin-left: 100px;
  }

  .testimonial-container{
  margin-left: 70px;
  font-size: 6vw;
  }
}

input[type="text"],
input[type="email"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}
input[type="submit"] {
  background-color: #868686;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
input[type="submit"]:hover {
  background-color: #868686;
}
.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

.logo {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(./logo.png);
  width: 600px;
  height: 400px;
  position: absolute;
  right: 0px;
  top: 60px;
  opacity: 0.5;
  z-index: 0;
  background-position: right;
  right: 3vw;
}


.fiveStar{
  background-image: url('./images/google-reviews-icon.png');
  width: 180px;
  height: 100px;
  position: relative;
  left: -38px;
  margin-top: -10px;
  background-repeat: no-repeat;
  background-size: contain;
}
